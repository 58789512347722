html,
window {
  -moz-transform: scale(0.85, 0.85);
  zoom: 0.85;
  zoom: 85%;
}

.top-0 {
  top:0 !important;
}

.bordered {
  th, td {
    border: 1px solid #e0e0e0 !important;
  }
}

.bg-none {
  background: none !important;
}

.mpk-full.viewport-height {
  height: 100% !important;
}

.mpk-full.viewport-width {
  width: 100% !important;
}

.mpk-form-wrapper {
  // max-width: inherit !important;
  // padding: 0 20px;
}

.rmd-table-cell {
  z-index: 0 !important;
}

.mpk-data-table .rmd-table-cell--sticky .rmd-menu {
  position: fixed !important;
  z-index: 1000000;
}

.mpk-appbar {
  box-shadow: 0px 1px 0px rgb(0 0 0 / 10%);
  border-bottom: thin solid rgba(0,0,0,0.04);
  // background-color: white;
}

.mpk-sidebar {
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}

.mpk-sidebar {

  .mpk-menu .menu-group {
    border-bottom: none;
  }

  // background: white;
  // color: white;

  .subheader {
    background: none;
  }

  // .menu-group-divider {
  //   color: white;
  // }

  // .mpk-menu .menu-group {
  //   border-bottom: thin solid rgba(255, 255, 255, 0.4);
  // }

  // .mpk-menu .menus .rmd-icon {
  //   color: white;
  // }

  // .menu-group {
  //   border-bottom: none !important;
  // }
}

.mpk-commandbar {
  background-color: #fff9f6;
  border-bottom: thin solid rgba(0,0,0,.064)!important;
}

$gradient-primary-2: #eee;
$gradient-primary-1: #e2e2e2;

.react-datepicker-popper {
  z-index: 5 !important;
}

.account {
  background: #e2e2e2;
  overflow-x: hidden;
  position: relative;
  .bg {
    positon: fixed;
    overflow: hidden;
    width: 100%;
    height: 100%;
    z-index: 0;
    .parallelogram-1 {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.54;
      -webkit-transform: skew(56deg);
      -moz-transform: skew(56deg);
      -o-transform: skew(56deg);
      background: $gradient-primary-1; /* Old browsers */
      background: -moz-linear-gradient(
        top,
        $gradient-primary-1 56%,
        $gradient-primary-2 100%
      ); /* FF3.6-15 */
      background: -webkit-linear-gradient(
        top,
        $gradient-primary-1 56%,
        $gradient-primary-2 100%
      ); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(
        to bottom,
        $gradient-primary-1 56%,
        $gradient-primary-2 100%
      ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$gradient-primary-1', endColorstr='$gradient-primary-2',GradientType=0 ); /* IE6-9 */
    }
    .parallelogram-2 {
      position: absolute;
      top: 0;
      right: 0;
      width: 50%;
      height: 100%;
      opacity: 1;
      -webkit-transform: skew(56deg);
      -moz-transform: skew(56deg);
      -o-transform: skew(56deg);
      background: $gradient-primary-2; /* Old browsers */
      background: -moz-linear-gradient(
        top,
        $gradient-primary-1 0%,
        $gradient-primary-2 24%
      ); /* FF3.6-15 */
      background: -webkit-linear-gradient(
        top,
        $gradient-primary-1 0%,
        $gradient-primary-2 24%
      ); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(
        to bottom,
        $gradient-primary-1 0%,
        $gradient-primary-2 24%
      ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$gradient-primary-1', endColorstr='$gradient-primary-2',GradientType=0 ); /* IE6-9 */
    }
    .parallelogram-3 {
      position: absolute;
      top: 0;
      left: 0%;
      opacity: 0.24;
      width: 18%;
      height: 100%;
      -webkit-transform: skew(-12deg);
      -moz-transform: skew(-12deg);
      -o-transform: skew(-12deg);
      background: $gradient-primary-2; /* Old browsers */
      background: -moz-linear-gradient(
        top,
        $gradient-primary-1 0%,
        $gradient-primary-2 52%
      ); /* FF3.6-15 */
      background: -webkit-linear-gradient(
        top,
        $gradient-primary-1 0%,
        $gradient-primary-2 52%
      ); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(
        to bottom,
        $gradient-primary-1 0%,
        $gradient-primary-2 52%
      ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$gradient-primary-1', endColorstr='$gradient-primary-2',GradientType=0 ); /* IE6-9 */
    }
    .parallelogram-4 {
      position: absolute;
      top: 0;
      right: 30%;
      opacity: 0.32;
      width: 40%;
      height: 100%;
      -webkit-transform: skew(-12deg);
      -moz-transform: skew(-12deg);
      -o-transform: skew(-12deg);
      background: $gradient-primary-2; /* Old browsers */
      background: -moz-linear-gradient(
        top,
        $gradient-primary-2 0%,
        $gradient-primary-1 52%
      ); /* FF3.6-15 */
      background: -webkit-linear-gradient(
        top,
        $gradient-primary-2 0%,
        $gradient-primary-1 52%
      ); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(
        to bottom,
        $gradient-primary-2 0%,
        $gradient-primary-1 52%
      ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$gradient-primary-2', endColorstr='$gradient-primary-1',GradientType=0 ); /* IE6-9 */
    }
    .parallelogram-5 {
      position: absolute;
      top: 0;
      right: 5%;
      opacity: 0.4;
      width: 40%;
      height: 100%;
      -webkit-transform: skew(-12deg);
      -moz-transform: skew(-12deg);
      -o-transform: skew(-12deg);
      background: $gradient-primary-2; /* Old browsers */
      background: -moz-linear-gradient(
        top,
        $gradient-primary-2 0%,
        $gradient-primary-1 52%
      ); /* FF3.6-15 */
      background: -webkit-linear-gradient(
        top,
        $gradient-primary-2 0%,
        $gradient-primary-1 52%
      ); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(
        to bottom,
        $gradient-primary-2 0%,
        $gradient-primary-1 52%
      ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$gradient-primary-2', endColorstr='$gradient-primary-1',GradientType=0 ); /* IE6-9 */
    }
  }
}
